import { parseHTML } from '../../modules/Parsers';

const parseComments = (data, postAuthor) =>
  data.comments.map(comment => parseComment(comment, postAuthor));

const parseComment = (comment, postAuthor) => {
  const { author, created_utc, replies, id, likes, body_html } = comment;
  const isOP = author.name === postAuthor;
  const parsedBody = parseHTML(body_html);
  const parsedReplies =
    replies && replies.length > 0
      ? replies.map(reply => {
          return parseComment(reply, postAuthor);
        })
      : [];

  return {
    body: parsedBody,
    replies: parsedReplies,
    timeAgo: created_utc,
    byOP: isOP,
    author: author.name,
    vote: parseVote(likes),
    id,
  };
};

const parseSelftext = selftext => parseHTML(selftext ?? '');

export { parseComments, parseSelftext };

function parseVote(likes) {
  if (likes === null) return 0;
  return likes ? 1 : -1;
}
