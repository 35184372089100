import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from '../TimeAgo/TimeAgo';
import VoteButtons from './VoteButtons';
import { cc } from '../../modules/styles';

import './PostComment.scss';

/**
 * A single comment
 */

class PostComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      repliesExpanded: false,
    };
    this.expandReplies = this.expandReplies.bind(this);
  }

  expandReplies() {
    this.setState({ repliesExpanded: true });
  }

  render() {
    const {
      id,
      body,
      author,
      timeAgo,
      replies = [],
      byOP,
      postPermalink,
      top = false,
      className,
      vote,
      reddit,
    } = this.props;

    const hasReplies = replies && replies.length > 0;
    const showReplies =
      this.props.expandedReplies || this.state.repliesExpanded;

    return (
      <div
        className={cc(
          'PostComment',
          top ? 'PostComment--top' : 'ml3',
          showReplies ? 'PostComment--expanded' : '',
          className
        )}
      >
        <div className="pl3 pr3 mb3">
          <div className="bold-text w100 flex flex-y">
            <div
              className="PostComment__author mr1"
              style={{
                '--theme-color-light': '57%',
                '--theme-color-dark': '42%',
              }}
            >
              {byOP && (
                <span
                  className="PostComment__op-label pl1 pr1 mr2"
                  style={{
                    '--theme-color-light': '97%',
                    '--theme-color-dark': '97%',
                  }}
                >
                  OP
                </span>
              )}
              {author}
            </div>
            <VoteButtons
              className="flex flex-y push-right mr3"
              commentId={id}
              initialVote={vote}
              reddit={reddit}
            />
            <a
              href={`https://reddit.com${postPermalink}${id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline thin-text"
              style={{
                '--theme-color-light': '70%',
                '--theme-color-dark': '35%',
              }}
            >
              <TimeAgo
                timestamp={timeAgo}
                short
                className="inline-block text-right"
              />
            </a>
          </div>

          <div
            className="PostComment__content UserContent"
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </div>

        {hasReplies &&
          showReplies &&
          replies.map(reply => {
            const { id, author, timeAgo, replies, byOP, body, vote } = reply;
            return (
              <PostComment
                key={id}
                id={id}
                body={body}
                author={author}
                timeAgo={timeAgo}
                postPermalink={postPermalink}
                byOP={byOP}
                replies={replies}
                expandedReplies={this.props.expandedReplies}
                vote={vote}
                reddit={reddit}
                className="anim-reveal-down"
              />
            );
          })}

        {hasReplies && !showReplies && (
          <button
            className="PostComment__replies-button mt3 mb3 push-center"
            onClick={this.expandReplies}
            style={{
              '--theme-color-light': '42%',
              '--theme-color-dark': '42%',
            }}
          >
            Show {replies.length > 1 ? `${replies.length} replies` : 'reply'}
          </button>
        )}
      </div>
    );
  }
}

PostComment.propTypes = {
  id: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  timeAgo: PropTypes.number.isRequired,
  replies: PropTypes.arrayOf(PropTypes.object),
  byOP: PropTypes.bool,
  postPermalink: PropTypes.string.isRequired,
  expandedReplies: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default PostComment;
