import React from 'react';
import PropTypes from 'prop-types';
import { SUBTYPE } from '../../constants';
import ActionStore from '../../modules/ActionStore';
import Store, { getSubreddits } from '../../modules/DataStore';
import MenuChannel from './MenuChannel';
import MenuItem from './MenuItem';
import MenuSubheader from './MenuSubheader';
import { loadAll, switchSubscription } from '../../actions';
import './Menu.scss';

/**
 * App Menu
 */

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subreddits: getSubreddits() || [],
      channels: Store.get(Store.keys.CHANNELS) || [],
    };
    this.reddit = props.reddit;
    this.setChannels = this.setChannels.bind(this);
    this.setSubreddits = this.setSubreddits.bind(this);
  }

  setSubreddits(subreddits) {
    this.setState({ subreddits });
    Store.set(Store.keys.SUBREDDITS, subreddits);
  }

  setChannels(channels) {
    this.setState({ channels });
    Store.set(Store.keys.CHANNELS, channels);
  }

  componentDidMount() {
    //// Subreddits
    this.reddit
      .getSubscriptions()
      .then(data => {
        const subscriptions = parseSubscriptions(data);
        this.setState({ subreddits: subscriptions });
        Store.set(Store.keys.SUBREDDITS, subscriptions);
      })
      .catch(error => {
        showMessage(`Error getting subscribed subreddits: ${error}`);
      });

    //// Channels
    this.reddit
      .getMyMultireddits()
      .then(data => {
        const multireddits = parseMultireddits(data);
        this.setChannels(multireddits);
      })
      .catch(error => {
        showMessage(`Error getting subscribed channels: ${error}`);
      });

    ActionStore.on('channel-added', channel => {
      this.state.channels.push(channel);
      this.setChannels(this.state.channels);
    });

    ActionStore.on('subscription', subreddit => {
      this.state.subreddits.push(subreddit);
      this.setSubreddits(this.state.subreddits);
    });

    ActionStore.on('unsubscription', subreddit => {
      const foundIndex = this.state.subreddits
        .map(subs => subs.toLowerCase())
        .indexOf(subreddit.toLowerCase());
      if (foundIndex === -1) return;
      this.state.subreddits.splice(foundIndex, 1);
      this.setSubreddits(this.state.subreddits);
    });
  }

  render() {
    const { selection, username } = this.props;
    const { subreddits, channels } = this.state;
    const selectedType = selection.type || 0;
    const selectedName = selection.name || '';

    return (
      <div className="Menu">
        <MenuSubheader name={username || 'Username'} />
        <MenuItem
          name="Sign Out"
          onClick={() => ActionStore.dispatch('sign-out')}
        />

        <MenuItem
          name="Saved Posts"
          selected={selectedType === SUBTYPE.SAVED_POSTS}
          onClick={() => {
            switchSubscription({
              type: SUBTYPE.SAVED_POSTS,
              name: 'Saved Posts',
            });
          }}
        />

        <MenuSubheader
          name="Subreddits"
          className="mt5"
          onActionRequest={() => {
            ActionStore.dispatch('open-subreddit-searcher');
          }}
        />

        <MenuItem
          name="frontpage"
          selected={
            selectedType === SUBTYPE.SUBREDDIT && selectedName === 'frontpage'
          }
          onClick={() => {
            switchSubscription({ type: SUBTYPE.SUBREDDIT, name: 'frontpage' });
          }}
        />

        <MenuItem
          name="all"
          selected={
            selectedType === SUBTYPE.SUBREDDIT && selectedName === 'all'
          }
          onClick={() => {
            loadAll();
            switchSubscription({ type: SUBTYPE.SUBREDDIT, name: 'all' });
          }}
        />

        {subreddits
          .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
          .map(subreddit => {
            const isSelected =
              selectedType === SUBTYPE.SUBREDDIT && selectedName === subreddit;
            return (
              <MenuItem
                name={subreddit}
                key={subreddit}
                selected={isSelected}
                onClick={name => {
                  switchSubscription({ type: SUBTYPE.SUBREDDIT, name });
                }}
              />
            );
          })}

        <MenuSubheader
          name="Channels"
          className="mt5"
          onActionRequest={() => {
            ActionStore.dispatch('open-channel-creator');
          }}
        />

        {channels.map(channel => {
          const isSelected =
            selectedType === SUBTYPE.CHANNEL &&
            selectedName.toLowerCase() === channel.name.toLowerCase();
          return (
            <MenuChannel
              channel={channel}
              key={channel.name}
              selected={isSelected}
              onNavigation={() => {
                switchSubscription({
                  type: SUBTYPE.CHANNEL,
                  name: channel.name,
                });
              }}
            />
          );
        })}
      </div>
    );
  }
}

MainMenu.propTypes = {
  selection: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  }),
  username: PropTypes.string.isRequired,
  reddit: PropTypes.object.isRequired,
};

export default MainMenu;

// Helpers

const parseSubscriptions = subscriptionsData =>
  subscriptionsData.map(subscription => subscription.display_name);

const parseMultireddits = multisData =>
  multisData.map(multi => {
    return {
      name: multi.display_name,
      subreddits: multi.subreddits.map(sub => sub.display_name),
    };
  });
